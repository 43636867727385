<template>
  <div>
    <main>
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="auth-header">
              <buttonLink :destination="'/'" :vegangLogo="true"> </buttonLink>
              <button-link
                :isInternal="true"
                v-if="loggedIn && userType == 'supplier'"
                :content="' Go To Supplier Dashboard'"
                :destination="'/supplier'"
                :filled="true"
              >
              </button-link>
              <button-link
                :isInternal="true"
                v-if="!loggedIn"
                :content="'Login For dropshipper'"
                :destination="'/login/retailer'"
                :outlined="true"
                :login="true"
              >
              </button-link>
            </div>
          </div>
        </div>
      </div>
      <div class="auth-page for-retailer">
        <div class="authpage-header">
          <h1 class="title for-retailer">
            Connect your <span class="green-text">Shopify</span> store
            <span class="sub for-retailer"
              >Choose from a range of ethically sourced planet-friendly items,
              Easily<br />
              sync products with your shop and start selling to your audience!
            </span>
          </h1>
        </div>

        <div class="auth-box">
          <div class="errorMessage-container" v-if="showAuthError">
            <div class="material-icons error-icon">cancel</div>
            <div>
              Ops! something happened
              <span>{{ errorMessage }}</span>
            </div>
          </div>
          <div class="form-body">
            <div class="form">
              <CustomInput
                type="text"
                id="shopify-url"
                label="Website"
                v-model="shopifyURL"
                :connect="true"
                prefix="http://"
                placeholder=" YourStoreName.myshopify.com"
                :discription="'Your Store Name and URL may be different. Be sure to refer to the URL, and not the Store Name.'"
                :required="true"
              />

              <button
                class="vgang-button form-button"
                @click="connectShop()"
                :disabled="IntegrationButtonIsDisabled"
                :class="IntegrationButtonIsDisabled ? 'disabled' : ''"
              >
                Connect my shop
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// Components
import CustomInput from "@/components/CustomInput.vue";
import buttonLink from "@/components/bases/ButtonLink.vue";
// Models
import User from "@/models/user";

// Services
import { uuid } from "vue-uuid";
import CommonData from "@/services/CommonData";
import RegisterationSetup from "@/services/RegisterationSetup";
import { setVariable, getVariable } from "@/utils/conditionalStoreVariables";
export default {
  metaInfo: {
    title: "vgang|ConnectShop-retailer",
    meta: [
      { name: "description", content: "Do you want to be a retailer?click me" },
    ],
  },
  data() {
    return {
      userType: "",
      showAuthError: false,
      errorMessage: "",
      user: new User("", ""),
      uuid: uuid.v1(),
      register: {
        email: "",
        password: "",
        DeviceId: this.$uuid.v4(),
        userType: "retailer",
        service: "vgang",
      },
      termsAndConditionsAccecpted: false,
    };
  },
  components: {
    CustomInput,

    buttonLink,
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.checkRole();
    }
  },
  methods: {
    checkRole() {
      const user = getVariable("user");
      if (user) {
        CommonData.getCurrentUser()
          .then(function (response) {
            this.userType = response.data.role;
            setVariable("role", this.userType);
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        return;
      }
    },
    connectShop() {
      const params = {
        storeUrl: this.shopifyURL,
        platformName: "shopifyfeeder",
      };
      RegisterationSetup.installLinkRetailer(params)
        .then((response) => {
          this.redirectURL = response.data.redirectUrl;
          window.location.replace(this.redirectURL);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style>
.prefix {
  left: 12px;
  color: #667085;
  position: absolute;
  border-right: 1px solid #d0d5dd;
  z-index: 2;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
}
</style>
